import React from "react";
import queryString from 'query-string';
import './App.css';

// import Loader from "react-js-loader";

import Nocode from "./Nocode";
import Iframe from "./Iframe";
// if (process.env.NODE_ENV === 'production') {
//   console.log = function () {};
// }

console.log(window.location.search, "Url in console");
const parsed = queryString.parse(window.location.search);
// console.log(parsed,"parsed")
// if(parsed.buttonid || parsed.buttonid)
// {
//   alert("Found Button ID")
// } else if (parsed.ibuttonid || parsed.ibuttonid) {
//   alert("Found Iframe buttonid");
// }

function getParsedButton() {
  console.log(parsed.buttonid,parsed.ibuttonid,"parsed btnid , parsed itbnid" , "inside getParsedButton" )
  if(parsed.buttonid ) {
    console.log("inside if")
    return <Nocode parsed={parsed} />
  } else if(parsed.ibuttonid ) {
    console.log("else block ")
    return <Iframe parsed={parsed} />
  } 
  // else 
  // {
  //   return (
  //     <div className="App">
  //       {/* <h1>Hii</h1> */}
  //       {/* <Spinner /> */}
  //       <div className={"row"}>
  //          <div className={"item"}>
  //             <div className="loader_wrapper">
  //               {/* <Loader type="bubble-loop" bgColor={"#212529"} title={"Please wait"} color={'#212529'} height="60" size={50} /> */}
  //               <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  //               <div className="card-info text-center mb-3">
  //                   <div className="card-info text-center">
  //                       <h6 className="card-title w-75 mx-auto mb-0 lh-base ng-binding"> Please wait </h6>
  //                   </div>
  //               </div>
  //             </div>
  //           </div>
           
  //       </div>
  //     </div>
  //   )
  // }
};

const App = () => {
  return (
    getParsedButton()
  );
};

export default App;
