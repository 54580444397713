import React, { useEffect, useState } from "react";
//import Iframe from 'react-iframe'
import axios from "axios";
import { env as environment } from "./actions/environment";

const IframePage = (props) => {
  const [apiResponse, setApiResponse] = useState(null);
  const [params,setParams] = useState(null);
  // console.log(apiResponse,"apiResponse")
  // const navigate = useNavigate();

  console.log(props.parsed,"iframe js ");
  const [ifmpage, setIfmpage] = useState({
    page: false,
    redirectLink: "",
    opacity: true,
  });

  useEffect(() => {
    getiframeLink();
  }, []);
  

  const redirectToCorrectURL = () => {
    const redirectURL = sessionStorage.getItem("redirectURL");
  
    if (redirectURL) {
      // Check if angle brackets are present and extract content inside them
      const match = redirectURL.match(/<([^>]+)>/);
  
      if (match && match[1]) {
        // Case 2: Angle brackets present, generate new redirect URL
        const additionalPath = match[1];
        const newRedirectURL = `https://www.google.com/${additionalPath}`;
        console.log("Redirecting to:", newRedirectURL);
        window.location.replace(newRedirectURL);
      } else {
        // Case 1: Normal redirect
        console.log("Redirecting to:", redirectURL);
        window.location.replace(redirectURL);
      }
  
      // Remove the redirectURL from sessionStorage
      sessionStorage.removeItem("redirectURL");
    }
  };

  console.log(apiResponse,"apiResponse 5555555 ")
  useEffect(( ) => {

    const urlSearchString = window.location.search;
    console.log(urlSearchString,"urlSearchString")
   
    // const params = new URLSearchParams(urlSearchString);

    
    setParams(urlSearchString)
    
     }, []);

  console.log(params,"params2222")
   
  useEffect(() => {
    // if (!inputEmail) navigate("/buttons");
    // === Set iframe urls ===
    // console.log(params);
    // if (!buttonSelection) navigate("/");
    // if (buttonSelection === "bank_verify")
    //   setIframeUrl(environment.bankButton + params.trackId);
    // if (buttonSelection === "bank_verify_full_doc")
    //   setIframeUrl(environment.bankButtonFullDoc + params.trackId);
    // if (buttonSelection === "utility_verify")
    //   setIframeUrl(environment.utilityButton + params.trackId);

    // === Eventlistner for capture process ===
    window.addEventListener("message", iframeEventListner);
    // === Cleanup on unmount ===
    return () => {
      // setIframeUrl();
      window.removeEventListener("message", iframeEventListner);
    };
  }, []); //eslint-disable-line



    const fetchLastClick = async (sessionId) => {
      console.log(sessionId,"fetchlastlink")
      try {

        // API endpoint
        // const apiUrl = "https://stage2.diro.live/Zuul-1.0/User-2.0/getlastclickedlink";

        // Payload data
        const payload = {
          sessionid: sessionId,
        };

        // Making the POST request using Axios
        const response = await axios.post(environment.get_lastclicked_link, payload);

        // Store the response in the state variable
        setApiResponse(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    useEffect(()=>{
      if(apiResponse!==null && apiResponse!==undefined) {
        console.log("set redirect url in session storage",apiResponse)
        sessionStorage.setItem("redirectURL",apiResponse.data.redirecturl);
      }
    },[apiResponse])

  // === EventListener function ===
  const iframeEventListner = async (event) => {
    console.log("11111111", event?.data);
    const sessionId = event?.data?.sessionid;
    if(sessionId!==undefined && sessionId!==null){
      fetchLastClick(sessionId);
    }
    // const buttonId = event?.data?.buttonid;

    // === Event when Exit button is clicked ===`
    if (event.data === "Terminate session from guac") {
      console.log("Terminate session storge",sessionStorage.getItem("redirectURL"))
      // window.location.replace(sessionStorage.getItem("redirectURL"));
      // sessionStorage.removeItem("redirectURL")
      redirectToCorrectURL()
      window.removeEventListener("message", iframeEventListner);
    }
    // === Event when document is submitted successfully ===
    if(event?.data?.progress_status === "Submitted"){
      console.log("submitted session storge",sessionStorage.getItem("redirectURL"))
      // window.location.replace(sessionStorage.getItem("redirectURL"));
      // sessionStorage.removeItem("redirectURL")
      redirectToCorrectURL()
      window.removeEventListener("message", iframeEventListner);
    }
    // if (event?.data?.progress_status === "Submitted" && !statusUpdated) {
    //   if (event?.data?.category === "address") {
    //     sessionStorage.setItem("submitStatus", "address");
    //   }
    //   if (event?.data?.buttonid === environment.staticBankOnlyButtonId) {
    //     sessionStorage.setItem("submitStatus", "bank");
    //   }
    //   if (event?.data?.buttonid === environment.staticBankFullButtonId) {
    //     sessionStorage.setItem("submitStatus", "bankfull");
    //   }

      // window.removeEventListener("message", iframeEventListner);
      // setTimeout(() => {
      //   navigate("/");
      // }, 6000);

    //   setStatusUpdated(true);
    // }
  };


  // console.log(parsed, "Parsed place");
  // console.log(parsed.trackid);

  // if(ifmpage.opacity) {

  // }
  function isMobileOrTablet() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }
  function getiframeLink() {
    console.log("getiframeLink Hit ===================");

    // window.location.replace(res.data.verificationlink);
    setIfmpage((prev) => ({
      ...prev,
      page: true,
      redirectLink: environment.frontendurl + window.location.search,
    }));
    setTimeout(function () {
      setIfmpage((prev) => ({ ...prev, opacity: false }));
    }, 500);
  }

  return (
    <div className={"row if_wrap"}>
      {/* {ifmpage.page && ifmpage.redirectLink && <>
          {/* <iframe src={ifmpage.redirectLink} className={ifmpage.opacity ? "iframeWrap ifr_opacity":"iframeWrap"}></iframe> */}
      {/* <Iframe url={ifmpage.redirectLink} className={ifmpage.opacity ? "iframeWrap ifr_opacity":"iframeWrap"} /> 
        </> */}
      {ifmpage.page && ifmpage.redirectLink && (
        <React.Fragment>
          {console.log(ifmpage.page, ifmpage.redirectLink)}

          <iframe
            id="nocodemyframe"
            title="Diro nocode url"
            src={ifmpage.redirectLink}
            className={
              ifmpage.opacity ? "iframeWrap ifr_opacity" : "iframeWrap"
            }
            allow="clipboard-read *; clipboard-write *"
          ></iframe>
        </React.Fragment>
      )}
      <div className="App">
        {/* <h1>Hii</h1> */}
        {/* <Spinner /> */}
        <div className={"item"}>
          {/* {mobPupup === "Warning" || mobPupup === "Disable" ? (
            <div className="loader_wrapper warning_box warnIframe">
              <div className="warning_box">
                <p className="redirect_message">
                  Start this session from your desktop vs. mobile device for an
                  optimized user experience.
                </p>
                {mobPupup === "Warning" && (
                  <div style={{ textAlign: "right", width: "100%" }}>
                    <button className="skip_btn" onClick={redirect}>
                      Skip
                    </button>
                  </div>
                )}
              </div>
            </div>
          ) : ( */}
          <div className="loader_wrapper outerLoadIframe">
            {/* <Loader type="bubble-loop" bgColor={"#212529"} title={"Please wait"} color={'#212529'} height="60" size={50} /> */}
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="card-info text-center mb-3">
              <div className="card-info text-center">
                <h6 className="card-title w-75 mx-auto mb-0 lh-base ng-binding">
                  {" "}
                  Please wait{" "}
                </h6>
              </div>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default IframePage;
