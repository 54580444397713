const stage2 = {
  production: true,
  getverificationlink:
    "https://stage.diro.live/Zuul-1.0/organization-2.0/getverificationlinkpoc",
  get_lastclicked_link:
    "https://stage.diro.live/Zuul-1.0/User-2.0/getlastclickedlink",
  sentryDSN:
    "https://9548b813e2cf408e9f435bed219ebbed@o1042621.ingest.sentry.io/4505396217511936",
  frontendurl: "https://session.diro.live/server/#/client/",
};

const stage1 = {
  production: false,
  getverificationlink:
    "https://stage.diro.live/Zuul-1.0/organization-2.0/getverificationlinkpoc",
  get_lastclicked_link:
    "https://stage.diro.live/Zuul-1.0/User-2.0/getlastclickedlink",
  sentryDSN:
    "https://c8a0e4e1ac8741e7b6aa198888fe236f@o4505041099030528.ingest.sentry.io/4505396209582080",
  frontendurl: "https://session1.diro.me/server/#/client/",
};

const prodeu = {
  production: true,
  getBtnList: "https://prod.dirolabs.com/Zuul-1.0/boards-2.0/buttonlist",
  getbutton: "https://prod.dirolabs.com/Zuul-1.0/boards-2.0/getbutton",
  createUser: "https://prod.dirolabs.com/Zuul-1.0/organization-2.0/createUser",
  getUserKycInfo: "https://prod.dirolabs.com/Zuul-1.0/user-2.0/getUserKycInfo",
  downloadDoucment:
    "https://prod.dirolabs.com/Zuul-1.0/uploadkyc-2.0/downloadDoucment",
  signupdemobank:
    "https://prod.dirolabs.com/Zuul-1.0/organization-2.0/signupdemobank",
  //bank_invite_user_email:"https://diro.io/#/invitation",
  bank_invite_user_email: "https://diro.io/client/#/invitation",
  sendinviteemail:
    "https://prod.dirolabs.com/Zuul-1.0/organization-2.0/sendsmsfororg",
  // getverificationlink:"https://prodeu.dirolabs.com:8443/Zuul-1.0/organization-2.0/getverificationlinkpoc",
  getverificationlink:
    "https://prod.dirolabs.com/Zuul-1.0/organization-2.0/getverificationlinkpoc",
  frontendurl: "https://session.diro.io/server/#/client/",
  email: "nits.tyagi0@getMaxListeners.com",
  mobile: "9045750302",
  mcc: "+91",
  zipcode: "25001",
  dob: "1991-05-14",
  apikey: "0015572742fd0470ec30ac9c9477090f",
  buildeEnv: "prod",
  get_lastclicked_link:
    "https://prod.dirolabs.com/Zuul-1.0/User-2.0/getlastclickedlink",
  sentryDSN:
    "https://8d29ce719b8f4828aabe2e93d3899071@o305199.ingest.sentry.io/4505396225114112",
};

export { stage1 };
export { prodeu };
export { stage2 as env };
