import React from "react";
// import axios from "axios";
import { env as environment } from "./actions/environment";

const Nocode = (props) => {
  console.log("props.parsed ", props.parsed);
  window.location.replace(environment.frontendurl + window.location.search);

  return (
    <div className="App">
      {/* <h1>Hii</h1> */}
      {/* <Spinner /> */}
      <div className={"row"}>
        <div className={"item"}>
          <div className="loader_wrapper">
            {/* <Loader type="bubble-loop" bgColor={"#212529"} title={"Please wait"} color={'#212529'} height="60" size={50} /> */}
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="card-info text-center mb-3">
              <div className="card-info text-center">
                <h6 className="card-title w-75 mx-auto mb-0 lh-base ng-binding">
                  {" "}
                  Please wait{" "}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nocode;
